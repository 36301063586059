import React, { useEffect, useState } from "react";
import Accordion from "../common/components/Accordion";
import LogRocket from "logrocket";
import { terms } from "../common/Constants";

const EULAPage = () => {
    const [isChecked, setIsChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    let requestUrl = "https://widgets.vendy.money";
    const pathSegments = window.location.pathname.split('?')[0].split('/');
    let reference = pathSegments[pathSegments.length - 2];
    if(reference.startsWith("test_")) {
        requestUrl = "https://widgets.staging.vendy.money";
    } else if(reference.startsWith("sandbox_")) {
        requestUrl = "https://widgets.sandbox.vendy.money";
    }

    reference = reference.replace("test_", "").replace("sandbox_", "");

    useEffect(() => {
        if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') return;
        fetch(`${requestUrl}/api/payment/${reference}`, {
            headers: { 'Content-Type': 'application/json' }
        }).then(response => response.json()).then(data => {
            LogRocket.identify(data.msisdn, {
                ref: data["refId"],
                currency: data.currency,
                channel: data.channel,
                method: data.method,
                status: data.status
            });
        }).catch((err) => {
            console.error(err);
        });
    }, []);

    const handleButtonClick = async () => {
        try {
            setIsLoading(true);
            const response = await fetch(`${requestUrl}/api/payment/${reference}/terms`, {
                method: 'POST', headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({})
            });

            // Handle the response as needed...
            if (response.ok) {
                window.history.back();
                window.location.href = "https://wa.me/19526810782";
                // window.close(); // TODO CONFIRM IF THIS IS NECESSARY
            } else {
                // TODO ERROR TOAST HERE
                console.error('Error sending the request 1', response.status, response.statusMessage);
                setIsChecked(false);
                setIsLoading(false);
            }
        } catch (error) {
            // TODO ERROR TOAST HERE
            console.error('Error sending the request 2:', error);
            setIsChecked(false);
            setIsLoading(false);
        }
    };

    const domain = window.location.protocol+"//"+window.location.host;
    const checkboxUrl = domain+"/assets/images/checkbox";
    return <div className="eula container">
        <h1 className="heading">Terms and Condition</h1>
        <div style={{ marginTop: "24px" }}>Please read these terms and conditions carefully before using our services</div>
        <div>
            <h4 className="subheading">a. What's in these terms?</h4>
            <div>These terms tell you the rules governing your access to, and use of the VENDY PAYMENT PLATFORM operated through the <a href="https://www.vendy.money/">Website</a> or the IOS App Store or the Google Play Store (Merchant App), Web API, WhatsApp API, and is powered by Vendy Limited ("We",” Our” or “Us”). The Vendy Payment Platform (Unified Payment Platform) facilitates social commerce, e-commerce and online payment, using the following channels on a device:</div>
            <ul>
                <li>USSD (Unstructured Supplementary Service Data)</li>
                <li>WhatsApp (where applicable)</li>
            </ul>
        </div>
        <div>
            <h4 className="subheading">b. There are other terms that may apply to you</h4>
            <div>These terms of use refer to the following additional terms, which also apply to your use of our site:</div>
            <ul>
                <li>Our <a href="https://vendy.ng/privacy-policy/"> Privacy Policy </a></li>
                <li>Our Cookie Policy,  which sets out information about the cookies on our site.</li>
            </ul>
        </div>
        <div>
            <h4 className="subheading">c. We may make changes to these T & Cs</h4>
            <div>We amend these Terms and Conditions from time to time by posting a revised version on our site and continuous usage constitutes acceptance of the revised Terms and Conditions as may be amended from time to time. Every time you wish to use our site, please check these terms to ensure you understand the terms that apply at that time. These terms were most recently updated on <strong>[15th March, 2023]</strong>. In this Agreement, “You”, “Your” or “Customer” means any person or entity using the Unified Payment Platform.</div>
        </div>
        {terms.map((section, index) => <Accordion key={index} props={section}/>)}
        <div className="footer-container">
            <div className="checkbox-container">
                <label>
                    <input type="checkbox" id="agree" checked={isChecked} onChange={() => setIsChecked(!isChecked)}/>
                    <img className="chakra-checkbox" src={checkboxUrl+(isChecked ? "-selected" : "")+".svg"} alt="checkbox" />
                    I have read, understood, and agree with the above terms and conditions
                </label>
            </div>
            <button className={`chakra-button`} onClick={handleButtonClick} disabled={!isChecked || isLoading}>
                { isLoading ? "Updating, please wait..." : "Accept and continue" }
            </button>
        </div>
    </div>;
};

export default EULAPage;
