// import { isMobile } from "react-device-detect";
// import cogoToast from "cogo-toast";

export const detectDeviceType = () => {
	const userAgent = window.navigator.userAgent;
	if (/android/i.test(userAgent)) {
		return 'Android';
	} else if ((/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) || navigator.platform === 'iPhone' ||
		navigator.platform === 'MacIntel' || navigator.platform === 'MacPPC' ||
		navigator.platform === 'iPad' || navigator.platform === 'iPod') {
		return 'iOS';
	} else {
		return 'unknown';
	}
};

export const detectOS = () => {
	let name = "Unknown";
	let version = "";

	const userAgent = window.navigator.userAgent;
	if (userAgent.includes("Android")) {
		name = "Android";
		const startIndex = userAgent.indexOf("Android");
		const endIndex = userAgent.indexOf(";", startIndex);
		version = userAgent.substring(startIndex, endIndex);
	} else if (userAgent.includes("iPhone") || userAgent.includes("iPad")) {
		name = (userAgent.includes("iPad") ? "iPad" : "iPhone")+" OS";
		const startIndex = userAgent.indexOf("OS");
		const endIndex = userAgent.indexOf(" like Mac", startIndex);
		version = userAgent.substring(startIndex + 3, endIndex).replace(/_/g, ".");
	} else if (userAgent.includes("Win")) {
		name = "Windows";
		if (userAgent.includes("Windows NT")) {
			const startIndex = userAgent.indexOf("Windows NT");
			const endIndex = userAgent.indexOf(";", startIndex);
			version = userAgent.substring(startIndex, endIndex);
		}
	} else if (userAgent.includes("Mac")) {
		name = "MacOS";
		if (userAgent.includes("Mac OS X")) {
			const startIndex = userAgent.indexOf("Mac OS X");
			const endIndex = userAgent.indexOf(")", startIndex);
			version = userAgent.substring(startIndex, endIndex);
			version = version.replace(/_/g, ".");
		}
	} else if (userAgent.includes("X11")) {
		name = "UNIX";
	} else if (userAgent.includes("Linux")) {
		name = "Linux";
	}

	return { name, version };
};

export const detectBrowser = () => {
	let name = "Unknown";
	let version = "";

	const userAgent = window.navigator.userAgent.toUpperCase();
	if (userAgent.match(/chrome|chromium|crios/i)) {
		name = "Chrome";
		version = userAgent.match(/(chrome|chromium|crios)\/(\d+(\.\d+)?)/i)[2];
	} else if (userAgent.match(/firefox|fxios/i)) {
		name = "Firefox";
		version = userAgent.match(/(firefox|fxios)\/(\d+(\.\d+)?)/i)[2];
	} else if (userAgent.match(/safari/i) && !userAgent.match(/chrome|chromium|crios/i)) {
		name = "Safari";
		version = userAgent.match(/version\/(\d+(\.\d+)?)/i)[1];
	} else if (userAgent.match(/edg/i)) {
		name = "Edge";
		version = userAgent.match(/edg\/(\d+(\.\d+)?)/i)[1];
	} else if (userAgent.match(/opr\//i)) {
		name = "Opera";
		version = userAgent.match(/opr\/(\d+(\.\d+)?)/i)[1];
	} else if (userAgent.match(/msie|trident/i)) {
		name = "Internet Explorer";
		version = userAgent.match(/(msie |rv:)(\d+(\.\d+)?)/i)[2];
	}

	return { name, version };
};

export const isVersionBeforeOrEqual = (version1, version2) => {
	const parts1 = version1.split('.').map(Number);
	const parts2 = version2.split('.').map(Number);

	for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
		const num1 = i < parts1.length ? parts1[i] : 0;
		const num2 = i < parts2.length ? parts2[i] : 0;

		if (num1 > num2) return false;
		if (num2 > num1) return true;
	}

	return true;
};

export const toastMessage = (type, message) => {
	// cogoToast[type]?.(message, {
	// 	position: isMobile ? "top-center" : "bottom-center",
	// 	bar: { size: '0px' },
	// 	hideAfter: 5,
	// });
};

export const supportedCurrencies = ['KES', 'NGN'];
export const phoneCodes = { 'ke': '+254', 'ng': '+234' };
export const supportedChannels = ['ussd', 'whatsapp'];

export const getQueryParams = _ => {
	let pairs = window.location.search.substring(1).split("&"),
		obj = {},
		pair,
		i;

	for (i in pairs) {
		if (pairs[i] === "") continue;

		pair = pairs[i].split("=");
		obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
	}

	return obj;
};

export const isInIframe = _ => {
	try {
		return window.self !== window.top;
	} catch (e) {
		return true;
	}
};

export const closeFrame = (transaction, domain) => {
	if (isInIframe()) {
		let message = {
			"event.type": transaction ?
				transaction['flag'] === 1 || transaction['reversed'] === 1 || transaction['failed'] === 1 ?
					"failure" : "success"
				: "cancel",
			data: transaction
		};
		window.top.postMessage(JSON.stringify(message), domain || '*');
	} else if (window.confirm("Are you sure you want to close this tab?")) {
		window.close();
	}
};
