import { useEffect } from "react";

const Step4 = ({ handleSubmit }) => {

    useEffect(_ => {
        handleSubmit();
    }, [handleSubmit]);

    return <div style={{ padding: "40px 30px", textAlign: "center" }}>

        <div style={{ padding: "20px 0", textAlign: "center" }}>
            <img className="spinner" src="assets/images/spinner.svg" style={{}} alt="Loading..." />
        </div>
        <h2 className="container-title">Please Wait</h2>
        <h4 className="container-title" style={{ fontSize: "12px", marginTop: "8px" }}>Payment request is awaiting authorization</h4>
    </div>;

};

export default Step4;
