import React, { useEffect, useState } from "react";
import LogRocket from "logrocket";
import {Link} from "react-router-dom";

const TermsConditions = () => {
    const [isChecked, setIsChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    let requestUrl = "https://widgets.vendy.money";
    const pathSegments = window.location.pathname.split('?')[0].split('/');
    let reference = pathSegments[pathSegments.length - 1];
    if(reference.startsWith("test_")) {
        requestUrl = "https://widgets.staging.vendy.money";
    } else if(reference.startsWith("sandbox_")) {
        requestUrl = "https://widgets.sandbox.vendy.money";
    }

    reference = reference.replace("test_", "").replace("sandbox_", "");

    useEffect(() => {
        if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') return;
        fetch(`${requestUrl}/api/payment/${reference}`, {
            headers: { 'Content-Type': 'application/json' }
        }).then(response => response.json()).then(data => {
            LogRocket.identify(data.msisdn, {
                ref: data["refId"],
                currency: data.currency,
                channel: data.channel,
                method: data.method,
                status: data.status
            });
        }).catch((err) => {
            console.error(err);
        });
    }, []);

    const handleButtonClick = async () => {
        try {
            setIsLoading(true);
            const response = await fetch(`${requestUrl}/api/payment/${reference}/terms`, {
                method: 'POST', headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({})
            });

            // Handle the response as needed...
            if (response.ok) {
                window.history.back();
                window.location.href = "https://wa.me/19526810782";
                // window.close(); // TODO CONFIRM IF THIS IS NECESSARY
            } else {
                // TODO ERROR TOAST HERE
                console.error('Error sending the request 1', response.status, response.statusMessage);
                setIsChecked(false);
                setIsLoading(false);
            }
        } catch (error) {
            // TODO ERROR TOAST HERE
            console.error('Error sending the request 2:', error);
            setIsChecked(false);
            setIsLoading(false);
        }
    };

    const domain = window.location.protocol+"//"+window.location.host;
    const checkboxUrl = domain+"/assets/images/checkbox";
    return <div className="eula-intro container">
        <h1 className="heading">Terms and Conditions</h1>
        <div className="subheading" style={{ marginBottom: "32px" }}>Here are a few things to know about using Vendy</div>
        <div className="summary" style={{ padding: "0 8px" }}>
            <ul className="key-points">
                <li>These terms outline the regulations governing your access and utilization of the Vendy Payment Platform</li>
                <li>Subject to the terms, your privacy matters to us. We handle your data as outlined in our Privacy Policy</li>
                <li>Subject to these terms, users must adhere to all rules and regulations applicable to them, including those set forth by our regulatory bodies</li>
            </ul>
        </div>
        <div className="full-terms-card">
            Please review our Terms and Conditions and Privacy Policy before accepting. By accepting, you confirm that you have read, understood, and given your explicit consent to abide by the terms.
            <ul className="links">
                <li><Link to={`/eula/${reference}/terms-conditions`}>Terms and Conditions</Link></li>
            </ul>
        </div>
        <div className="footer-container">
            <div className="checkbox-container">
                <label>
                    <input type="checkbox" id="agree" checked={isChecked} onChange={() => setIsChecked(!isChecked)}/>
                    <img className="chakra-checkbox" src={checkboxUrl+(isChecked ? "-selected" : "")+".svg"} alt="checkbox" />
                    I have read, understood, and agree with the above terms and conditions
                </label>
            </div>
            <button className={`chakra-button`} onClick={handleButtonClick} disabled={!isChecked || isLoading}>
                { isLoading ? "Updating, please wait..." : "Accept and continue" }
            </button>
        </div>
    </div>;
};

export default TermsConditions;
