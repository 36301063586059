import React, { useCallback, useState } from "react";

import * as Util from "../common/Util";
import Step1 from "./payment-form-steps/Step1";
import Step2 from "./payment-form-steps/Step2";
import Step3 from "./payment-form-steps/Step3";
import Step4 from "./payment-form-steps/Step4";
import Step5 from "./payment-form-steps/Step5";

const PaymentForm = ({ props }) => {

	const channelOptions = [
		{ value: "ussd", img: '/assets/images/ussd.svg', label: 'USSD' },
		props.currency?.toLowerCase() === "kes" ? null : { value: "whatsapp", img: '/assets/images/whatsapp.svg', label: 'Whatsapp' },
		// props.currency?.toLowerCase() === "kes" ? null : { value: "telegram", img: '/assets/images/telegram.svg', label: 'Telegram ' }
	].filter(Boolean);

	const amount = parseFloat(props.amount.toString()).toFixed(2);

	const [formData, setFormData] = useState({
		"domain": props.domain,
		"key": props.key,
		"step": channelOptions.find(item => item.value === props.channel) ? "4" : "1",
		"phoneNumber": props.phoneNumber || '',
		"amount": (amount || "0").replace(/\d(?=(\d{3})+\.)/g, '$&,'),
		"currency": props.currency,
		"disableButton": false,
		"disableReason": '',
		"isLoading": false,
		"isTest": props.isTest || false,
		"useSandbox": props.useSandbox || false,
		"channel": channelOptions.find(item => item.value === props.channel) ?? channelOptions[0],
		"chargeCustomer": typeof props.chargeCustomer === 'boolean' ? props.chargeCustomer : false
	});

	const updateFormData = newValues => {
		setFormData(prevState => {
			return { ...prevState, ...newValues };
		});
	};

	const handleSubmit = useCallback(_ => {
		if (formData.disableButton || formData.isLoading || !formData.phoneNumber || formData.phoneNumber.length < 7 || isNaN(formData.phoneNumber))
			return;

		let phoneNumber = formData.phoneNumber.replaceAll("+", "").trim();
		const country = (formData.currency || 'NGN').toLowerCase().slice(0, 2);
		const dialCode = Util.phoneCodes[country].toString().replaceAll("+", "");
		const regex = new RegExp(`^(0|${dialCode}0)+`);
		phoneNumber = phoneNumber.replace(regex, dialCode);

		updateFormData({ "isLoading": true });
		const env = props.isTest ? (props.useSandbox ? '.sandbox' : '.staging') : '';
		const url = `https://api${env}.vendy.money/public/transactions/initiate-transaction`;
		const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.key };
		const body = {
			"amount": props.amount.toString(),
			"msisdn": phoneNumber,
			"channel": formData.channel.value,
		};
		if(props.ref) body.ref = props.ref;

		fetch(url, {
			headers, method: 'POST',
			body: JSON.stringify(body),
		}).then(response => response.json()).then(data => {
			if (data.status && data.code === 200 && data.data) {
				updateFormData({
					"isLoading": false, ref: data.data.refid, step: 5
				});
				// handler({ "showStatus": true });
			}
		}).catch((err) => {
			console.error(err);
			updateFormData({ isLoading: false });
		});
	}, [formData, props]);

	// useEffect(_ => {
	// 	fetchTransactionDetails();
	// }, [fetchTransactionDetails]);

	const updateProps = { formData, updateFormData, channelOptions, handleSubmit };

	return <div>
		{({
			"1": <Step1 {...updateProps} />,
			"2": <Step2 {...updateProps} />,
			"3": <Step3 {...updateProps} />,
			"4": <Step4 {...updateProps} />,
			"5": <Step5 {...updateProps} />
		})[formData.step]}

		<div className="text-warning">{formData.disableReason ? <span>{formData.disableReason}</span> : ''}</div>
	</div>
};

export default PaymentForm;
