import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import * as Util from "./common/Util";
import PaymentForm from "./components/PaymentForm";
import PaymentChannel from "./components/PaymentChannel";
import PaymentStatus from "./components/PaymentStatus";

const PaymentApp = () => {
	const { reference } = useParams();
	const [values, setValues] = useState({
		"initialized": false,
		"showConfirmation": false,
		"showStatus": false,
		"key": '', "ref": '',
		"useSandbox": false,
		"phoneNumber": '',
		"isTest": false,
		"currency": '',
		"amount": '',
		"channel": ''
	});

	const setState = (newValues) => {
		setValues(prevState => {
			return { ...prevState, ...newValues };
		});
	};

	useEffect(() => {
		const ref = reference.toLowerCase().trim();
		const isTest = ref.startsWith('test_');
		const useSandbox = ref.startsWith('sandbox_');
		try {
			const headers = { 'Content-Type': 'application/json' };
			const env = isTest ? '.staging' : (useSandbox ? '.sandbox' : '');
			const url = `https://api${env}.vendy.money/public/transactions/payment-url/${ref.replace('test_', '')}`;
			fetch(url, { headers }).then(response => response.json()).then(message => {
				if(!message || !message['data']) return;

				let currency = (message['data'].currency || '').trim().toUpperCase();
				if (!Util.supportedCurrencies.includes(currency)) return;

				if (message['data'].key && message['data'].amount && !isNaN(message['data'].amount)) {
					if (values.initialized) return;
					const showStatus = !!(message['data']['refid'] || message['data']['refId']);
					const refId = message['data']['refid'] || message['data']['refId'] || message['data'].ref || '';
					setState({
						"initialized": true, currency,
						"key": message['data'].key,
						"ref": showStatus ? refId : (message['data'].ref || ''),
						"channel": message['data'].channel?.trim()?.toLowerCase(),
						"phoneNumber": message['data'].phoneNumber || '',
						"amount": message['data'].amount,
						"useSandbox": message['data'].useSandbox,
						"isTest": message['data'].isTest, showStatus,
						"chargeCustomer": message['data'].chargeCustomer || false
					});
				}
			}).catch((err) => {
				console.error(err);
			});
		} catch (_) { }
	}, []);

	return !values.initialized ? <div/> : <div>
		<div style={{ height: "100vh", display: "flex" }}>
			<div className="widget-container">
				<div className="sub-container">
					<div className="widget-title">
						<img src={"assets/images/logo.png"} alt="Vendy" />
						<h1>Pay with Vendy</h1>
					</div>
					{values.initialized && values.showStatus ? <PaymentStatus props={values} /> : (
						values.initialized && values.showConfirmation ? <PaymentChannel props={values} handler={setState} /> : (
							values.initialized && values.amount && parseFloat(values.amount.toString()) > 10 ?
								<PaymentForm props={values} handler={setState} /> : <div />
						)
					)}
				</div>
			</div>
		</div>
	</div>;
};

export default PaymentApp;
