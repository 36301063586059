import React from "react";

const Step2 = ({ updateFormData, formData }) => {

    const handleSubmit = event => {
        event.preventDefault();
        updateFormData({ "charge-amount": event.target.amount.value, "step": 3 });
    };

    return <div>
        <div style={{ marginTop: "40px", textAlign: "center" }}>
            <span className="container-title">
                Payment Request of
            </span>
            <div>
                <span className="currency-amount">{formData.amount}</span>
                <span className="muted">{formData.currency.toUpperCase()}</span>
            </div>
        </div>

        <form onSubmit={handleSubmit}>
            <div>
                <input
                    id="phoneNumber"
                    type="text" maxLength={13}
                    value={formData.phoneNumber || ''}
                    className="phone"
                    readOnly
                />
            </div>
            <div style={{ marginTop: "3rem" }}>
                <p style={{ textAlign: "center", color: "#062558", fontWeight: 500, fontSize: "0.875rem", lineHeight: "1.25rem" }} > How much will you be charging for this service? </p>
                <div style={{ "position": "relative" }}>
                    <input
                        className='form-input'
                        type="text"
                        name="amount"
                        pattern="^\d+(,(\d+))?(,(\d+))?(\.(\d+))?$"
                        placeholder="Enter amount"
                        required
                    />
                    <div className="input-currency">
                        {formData.currency.toUpperCase()}
                    </div>
                </div>
            </div>
            <button className={formData.disableButton || formData.isLoading || !formData.phoneNumber || formData.phoneNumber.length < 7 ? "btn-disabled" : "btn-default"}
                disabled={formData.disableButton || formData.isLoading || !formData.phoneNumber || formData.phoneNumber.length < 7}
                style={{ marginBottom: "20px" }} type="submit">
                {formData.isLoading ? <img className="spinner grayscale" src={"assets/images/spinner.svg"} style={{ margin: "-10px 0 -10px", "width": "28px" }} alt="Loading..." /> : "Proceed"}
            </button>
        </form>
    </div>;

};

export default Step2;
