import React from "react";

const Step3 = ({ updateFormData, formData }) => {

    return <div>
        <div className="container-title" style={{ marginTop: "40px", marginBottom: "2rem", textAlign: "center" }}>
            Transaction Details
        </div>
        <div className="table-div">
            <table style={{ "width": "100%" }}>
                <tbody style={{ "whiteSpace": "nowrap" }}>
                    <tr>
                        <td className="py-3">
                            Request amount
                        </td>
                        <td style={{ "textAlign": "right" }}>
                            {`${formData.currency.toUpperCase()} ${formData.amount}`}
                        </td>
                    </tr>
                    <tr>
                        <td className="py-3">
                            Applicable Fees
                        </td>
                        <td style={{ "textAlign": "right" }}>
                            {`${formData.currency.toUpperCase()} ${10}`}
                        </td>
                    </tr>
                    <tr>
                        <td className="py-3">
                            Phone Number
                        </td>
                        <td style={{ "textAlign": "right" }}>
                            {formData.phoneNumber}
                        </td>
                    </tr>
                    <tr>
                        <td className="py-3">
                            Transaction method
                        </td>
                        <td style={{ "textAlign": "right", "textTransform": "capitalize" }}>
                            {formData.channel.value}
                        </td>
                    </tr>
                    <tr>
                        <td className="py-3">
                            Total
                        </td>
                        <td style={{ "textAlign": "right" }}>
                            {`${formData.currency.toUpperCase()} ${formData.amount + 10}`}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div className="fees-div">
            <div style={{ "flex": "1 1 0" }}>
                <div style={{ color: "#062558", fontWeight: 600, fontSize: "14px", lineHeight: "17px", paddingBottom: "0.5rem" }}>
                    Transaction fees
                </div>
                <div style={{ color: "#062558", fontWeight: 400, fontSize: "14px", lineHeight: "17px" }}>
                    Customer pays transaction fees
                </div>

            </div>
            <div style={{ "flex": "none", "marginTop": "auto", "marginBottom": "auto" }}>
                <label className="custom-toggle">
                    <div style={{ "position": "relative" }}>
                        <input
                            type="checkbox"
                            name="chargeCustomer"
                            className="sr-only"
                            value={formData.chargeCustomer}
                            defaultChecked={formData.chargeCustomer}
                            onChange={e => updateFormData({ "chargeCustomer": e.target.checked })}
                            disabled={formData.disableButton || formData.isLoading}
                        />
                        <div className="line" />
                        <div className="dot" />
                    </div>
                </label>
            </div>
        </div>

        <button className={formData.disableButton || formData.isLoading || !formData.phoneNumber || formData.phoneNumber.length < 7 ? "btn-disabled btn-submit" : "btn-default btn-submit"}
            style={{ marginBottom: "20px" }}
            type="button"
            disabled={formData.disableButton || formData.isLoading || !formData.phoneNumber || formData.phoneNumber.length < 7}
            onClick={e => {
                e.preventDefault();
                updateFormData({ step: 4 });
            }}>
            {formData.isLoading ? <img className="spinner grayscale" src={"assets/images/spinner.svg"} style={{ margin: "-10px 0 -10px", "width": "28px" }} alt="Loading..." /> : "Proceed"}
        </button>
    </div>

};

export default Step3;
