import React, { useCallback, useEffect, useMemo, useState } from "react";

const TransactionProgressBar = ({ currentValue, newValue, indicators }) => {
	const [ progress, setProgress ] = useState({ value: currentValue || 0, percent: 0 });
	const setState = (newValues) => {
		setProgress(prevState => {
			return {...prevState, ...newValues};
		});
	};

	const duration = 350, progressIndicators = useMemo(() => indicators || [{
		icon: "assets/images/icons/indicator-1.png",
		tag: "processing...",
		label: "Vendy"
	}, {
		icon: "assets/images/icons/indicator-2.png",
		activeIcon: "assets/images/icons/indicator-2a.png",
		tag: "authorizing...",
		label: "Customer"
	}, {
		icon: "assets/images/icons/indicator-3.png",
		activeIcon: "assets/images/icons/indicator-3a.png",
		tag: "debited...",
		label: "Debited"
	}, {
		icon: "assets/images/icons/indicator-4.png",
		activeIcon: "assets/images/icons/indicator-4a.png",
		tag: "completed...",
		label: "Done"
	}], [ indicators ]);

	const renderProgressAnimation = useCallback(() => {
		let percentPerStep = Math.floor(100 / (progressIndicators.length - 1));
		let stepCount = Math.abs(newValue - currentValue);
		let timeout = duration / (percentPerStep * stepCount);

		let percent = currentValue * percentPerStep;
		setState({ value: currentValue, percent: percent });
		let animation = setInterval(_ => {
			let newValues = {
				percent: currentValue < newValue ? ++percent : --percent,
				value: Math.floor(percent / percentPerStep)
			};

			if((newValue < currentValue && newValues.percent <= (newValue * percentPerStep)) || (newValue > currentValue && newValues.percent >= (newValue * percentPerStep))) {
				newValues.value = newValue;
				clearInterval(animation);
			}

			setState(newValues);
		}, timeout);
	}, [ currentValue, newValue, progressIndicators ]);

	useEffect(() => {
		if(newValue === undefined || currentValue === undefined || newValue === currentValue) return;
		if(newValue < currentValue) setState({ value: newValue });
		renderProgressAnimation();
	}, [ currentValue, newValue, renderProgressAnimation ]);

	return <div className="progress-wrapper">
		<div className="progress-bar">
			<div className="active-progress" style={{"width": progress.percent+"%"}}>&nbsp;</div>
		</div>
		{progressIndicators.map((indicator, index) => <div className="progress-indicator" key={index}>
			<div className={"indicator-icon"+(progress.value >= index ? " active" : "")}>
				<img src={progress.value >= index ? (indicator.activeIcon || indicator.icon) : indicator.icon} alt={indicator.tag} />
			</div>
			<div className={"indicator-label"+(progress.value >= index ? " active" : "")}>{indicator.label}</div>
		</div>)}
	</div>;
};

export default TransactionProgressBar;
