import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import * as Util from "./common/Util";
import PaymentForm from "./components/PaymentForm";
import PaymentChannel from "./components/PaymentChannel";
import PaymentStatus from "./components/PaymentStatus";
import ErrorScreen from "./components/ErrorScreen";
import PaymentLanding from "./components/PaymentLanding";
import PaymentPage from "./PaymentPage";
import EULAPage from "./components/EULAPage";
import TermsConditions from "./components/TermsConditions";

const App = () => {

    window.onmessage = (e) => {
        try {
            let message = JSON.parse(e.data);
            if (message['event.type'] === "new-transaction" && message['data']) {
                let currency = (message['data'].currency || '').trim().toUpperCase();
                if (!Util.supportedCurrencies.includes(currency)) return;
                let channel = message['data'].channel;

                if (message['data'].domain && message['data'].key && message['data'].amount && !isNaN(message['data'].amount)) {
                    if (values.initialized) return;
                    setState({
                        "initialized": true, currency,
                        "phoneNumber": message['data'].phoneNumber || '',
                        "key": message['data'].key, "ref": message['data'].ref || '',
                        "channel": channel?.trim()?.toLowerCase(),
                        "amount": message['data'].amount,
                        "isTest": message['data'].isTest,
                        "useSandbox": message['data'].useSandbox,
                        "chargeCustomer": message['data'].chargeCustomer,
                        "domain": message['data'].domain
                    });
                }
            }
        } catch (_) { }
    };

    const [values, setValues] = useState({
        "initialized": false,
        "showConfirmation": false,
        "showStatus": false,
        "key": '', "ref": '',
        "phoneNumber": '',
        "isTest": false,
        "useSandbox": false,
        "currency": '',
        "amount": '',
        "channel": '',
        "domain": '*',
    });

    const setState = (newValues) => {
        setValues(prevState => {
            return { ...prevState, ...newValues };
        });
    };

    useEffect(() => {
        if (window.location.pathname.trim() === '/') {
            window.top.postMessage(JSON.stringify({ 'event.type': 'ready' }), values.domain);
        }
    });

    return values.initialized ? <div>
        <button type="button" className="close-button" onClick={e => { e.preventDefault(); Util.closeFrame(null, values.domain); }}>&#10006;</button>
        <div style={{ height: "100vh", display: "flex" }}>
            <div className="widget-container">
                <div className="sub-container">
                    <div className="widget-title">
                        <img src={"assets/images/logo.png"} alt="Vendy" />
                        <h1>Pay with Vendy</h1>
                    </div>{
                        window.location.pathname.trim() !== '/' ? <ErrorScreen /> : (
                            values.initialized && values.showStatus ? <PaymentStatus props={values} /> : (
                                values.initialized && values.showConfirmation ? <PaymentChannel props={values} handler={setState} /> : (
                                    values.initialized && values.domain !== '*' && values.amount && parseFloat(values.amount.toString()) > 10 ?
                                        <PaymentForm props={values} handler={setState} /> : <div />
                                )
                            )
                        )}
                </div>
            </div>
        </div>
    </div> : <div>
        <Router>
            <Routes>
                {/*<Route exact path="/" element={<Home />} />*/}
                <Route path="/eula/:reference" element={<TermsConditions />} />
                <Route path="/eula/:reference/terms-conditions" element={<EULAPage />} />
                <Route path="/:reference" element={<PaymentPage />} />
                <Route path="/pay/:transactionRef" element={<PaymentLanding />} />
                <Route path="/auth/:transactionRef" element={<PaymentLanding />} />
                {/*<Route element={<NotFound />} />*/}
            </Routes>
        </Router>
    </div>;
};

export default App;
