export const terms = [{
    title: "1. Definitions",
    content: [
        "“Account Holder” means any legal entity or natural person to who has account in a financial institution",
        "“Bank Account” means a transaction account maintained by an individual in a licensed financial institution profiled for Our services and can be used as means of payment for goods and services",
        "“Customer” means Payer or Seller as defined under these Terms and Conditions or other end users of Our services or platform",
        "“Payer” means the individual or entity signed up for Our Services and legally responsible for charges made on a specific Bank Account in respect to Transactions conducted on Our platform or services",
        "“Seller” anyone (individual or any owner of an outlet providing goods and/or services for sale) enrolled on Our platform or services and assigned a Seller Code",
        "“Seller Code” means a number/code provided by Us to identify the Seller in a Transaction",
        "“Transaction” means any action between a Payer and a Seller that results in activity on the Payer’s Bank account, such as a purchase, refund, or reversal and settlement",
        "“Unique Information” means each Account Holder’s identification details such as name, Seller Code, and other connectivity protocols."
    ]
}, {
    title : "2. The Agreement",
    content: [
        "You confirm and agree that the following terms and conditions shall govern Your use of Our Unified Payment Platform.",
        "By clicking on the “I AGREE” button or a similar affirmation, or by acknowledging acceptance of these Terms and Conditions by any other method prescribed, or by using or continuously accessing the Unified Payment Platform through any means permissible including, without limitation via a computer or a mobile application, You acknowledge and agree that:",
        "You have reviewed and understand these Terms and Conditions",
        "You agree to be legally bound by these Terms and Conditions and,",
        "Your use of the Unified Payment Platform and any related products or services will be governed by these Terms and Conditions.",
        "If You do not agree or You are not willing to be bound by these Terms and Conditions, You should not click on the “I AGREE” button and should not seek to obtain or use the Unified Payment Platform.",
        "We recommend that you print a copy of these terms for future reference."
    ]
}, {
    title: "3. Customer Capacity and Related Matters",
    content: [
        "By accepting these Terms and Conditions, You represent and warrant that:",
        "You or the person agreeing to these Terms and Conditions on Your behalf is 18 years of age or older",
        "All information provided by you to Our platform is true and correct in all respects You further represent and warrant that You have the legal authority to accept these Terms and Conditions and that such acceptance will be binding on You. We reserve the right, in Our sole discretion to refuse to provide You with the Our Services if You are in breach of any provisions of these Terms and Conditions."
    ]
}, {
    title: "4. Registration, KYC Verification, Anti-Money Laundering (AML) and Combating Financing of Terrorism (CFT)",
    content: [
        "As a user of this Unified Payment Platform, You will be required to register with us and provide private information such as your email, business name, phone number, etc.",
        "You are responsible for maintaining the safety and security of your identifying information. You are also responsible for all activities that occur under your account or password.",
        "We will seek to verify all information and KYC documents where applicable, (by us or through third parties) under applicable “know-your-customer” and anti-money laundering rules and regulations, the results of which shall be reasonably satisfactory to us in order to approve your use of the Unified Payment Platform."]
}, {
    title: "5. Payment Obligations",
    content: [
        "When You initiate and confirm a Transaction using the Unified Payment Platform, You agree to be bound by and pay for that Transaction. Do not commit to a Transaction unless You are ready to pay and have confirmed that all information provided is accurate as all completed Transactions are final.",
        "To prevent financial loss or possible violations of the law, We reserve the right to use Our discretion in disclosing details of any payments associated with You to the law enforcement agencies, or impacted third parties (including other users). Such disclosures may originate from an order of any trial court with the jurisdiction to compel such a disclosure.",
        "You agree and confirm that you will not use Our platform and services for money laundering, terrorism and any illegal activities or violate any law related to such.",
        "Normal charges or other charges for services (if applicable) by mobile network operators may be applied for each Transaction conducted via Our Services or platform.",
        "We may terminate the relationship at any time without notification if, in Our discretion, Our reputation and/or integrity is affected in an adverse manner by Your actions or disrupts others use of the Services. We may also suspend providing Our services to You if we are investigating any suspected misconduct by You."
    ]
}, {
    title: "6. Closing or Suspension of Your Account",
    content: [
        "We may suspend, restrict or terminate the provision of Our services (in whole or in part) without any liability whatsoever, if in any way We know or suspect that Your account is being used fraudulently, negligently or for illegal activities or if We must do so to comply with the law."
    ]
}, {
    title: "7. Indemnity",
    content: ["You agree to defend, indemnify, and hold Us, Our affiliates, representatives, agents, and third-party service providers harmless, from any claims, liabilities, damages, and costs (actual and consequential) arising from or related to Your use of the Unified Payment Platform."]
}, {
    title: "8. Limitation of Liability",
    content: [
        "You acknowledge and agree that, to the fullest extent provided by applicable law, we will not be liable to you or any other person under any circumstances, for any direct, indirect, special, incidental or consequential losses or damages of any nature arising out of or in connection with the use of or inability to use the Unified Payment Platform. Even if a representative of Unified Payment Platform has been advised or should have known the possibility of such damages",
        "To the fullest extent provided by applicable law, this disclaimer applies to any damages or injuries arising from any failure of performance, error, omission, interruption, deletion, defects, delay in operation or transmission, lost profits, loss of goodwill and any other tangible or intangible loss."
    ]
}, {
    title: "9. Disclaimer",
    content: [
        "Except as specifically provided to the contrary in this Terms and Conditions We make no representations or warranties to You concerning the specific quality of any services, or facilities provided under this Terms and Conditions.",
        "We disclaim, without limitation, any warranty or guarantee of any kind including express, implied and statutory warranties relating to the Unified Payment Platform.",
        "Vendy is not a money lender and does not hold or operate a money lending licence. More specifically, Vendy does not grant loans.",
        "By using Vendy's Platform and accessing the loan services offered by Vendy's financial partners, you acknowledge and agree that Vendy shall not be held liable for any claims, damages, losses, liabilities, disputes, or issues arising from or related to the loans.",
        "You are encouraged to thoroughly review and understand the terms and conditions concerning the loans. If you are in any doubt about the terms governing the loan, you should consider seeking independent financial advice from professional advisers who are fully aware of your personal circumstances."
    ]
}, {
    title: "10. Notices",
    content:[
        "The email address You supplied when You enrolled is regarded as Your chosen email address where notices may be given and documents in legal proceedings may be served.",
        "We are entitled to send any notice to Your email address or cell phone number supplied to Our platform. This communication will be regarded as having been received by You unless the contrary is proved.",
        "Any correspondence that we send to You by email will be considered to have arrived on the day that it was sent.",
        "We are entitled to send information to You via email or SMS to the contact mobile phone number You supplied."
    ]
}, {
    title: "11. Jurisdiction",
    content: [
        "You acknowledge and agree that these Terms and Conditions will be regarded as having been agreed to in Nigeria and any breach of this Agreement will be considered as having taken place within the Jurisdiction of the Federal Republic of Nigeria."
    ]
}, {
    title: "12. Fraud Prevention",
    content: [
        "You consent to us carrying out identity and fraud prevention checks and sharing information relating to Our services with the Nigerian Police or any fraud prevention, law enforcement or security agency.",
        "You consent to us providing details to the Nigerian Police or any fraud prevention, law enforcement or security agency, of any conduct on Your account that gives reasonable cause to suspect that Our service or platform is being used for improper purposes.",
        "You understand and agree that the record of this suspicion will then be available to other members of the Nigerian Police or any fraud prevention, law enforcement or security agency."
    ]
}, {
    title: "13. Termination by You",
    content:[ "You may terminate this Agreement by deleting your account on the Unified Payment Platform." ]
}, {
    title: "14. Customer's Warranties",
    content: [
        "You represent and warrant that at all times during the term of these Terms and Conditions:",
        "All representations and statements made by You to Your bank or third party when You signed up for Our service is true and correct in all respects, or in any other related document provided by You or on Your behalf, are true, accurate and complete in all material respects.",
        "You are engaged in a lawful business and have all necessary rights and authorisations to sell and distribute Your products and/or services in all applicable jurisdictions.",
        "You have sought legal advice regarding the effects or implications of the provisions of this T & Cs.",
        "You will comply, at Your own expense, with all laws, policies, guidelines, regulations, ordinances or rules applicable to You, these Terms and Conditions, end user data and the use of Our platform or services.",
        "You shall not use abusive, rude and offensive words in communicating with Our employees or agents through any means such as blog comments and support tickets."
    ]
}, {
    title: "15. ID and Password",
    content: [
        "You must select an ID and password to enable You access Customer’s payment gateway account and use Our Services. You must treat such information as confidential and restrict access to such ID, password, and account to Your employees and agents as may be reasonably necessary and consistent with the purposes of these Terms and Conditions and will ensure that each such employee and agent accessing and using the account is aware of and otherwise complies with all applicable provisions of these Terms and Conditions regarding such use and access.",
        "You are solely responsible for maintaining adequate security and control of all IDs, passwords, or any other codes for purposes of giving You access to Our services. We shall be entitled to rely on information We receive from You where such information contains valid IDs and passwords/PINs and may assume that all such information was transmitted by You."
    ]
}, {
    title: "16. Data Collection, Privacy and Security",
    content: [
        "You are solely responsible for the security of data residing on servers owned or operated by You, or a third party designated by You.",
        "You shall comply with all applicable laws, policies and regulations governing the security, privacy, collection, retention and use of end user data, including, without limitation, financial information, card account numbers, and all other personally identifiable end user information.",
        "You are solely responsible for verifying the accuracy and completeness of all Transactions submitted on and processed on Our platform or service associated with Your account and verifying that all corresponding funds are accurately processed.",
        "You acknowledge and agree that We shall not be liable for any improperly processed or unauthorised Transactions or illegal or fraudulent access to Your account. "]
}, {
    title: "17. Intellectual Property",
    content: [
        "You agree that We own and retain all rights, title and interest in and to Our Trademarks, Unified Payment Platform, services, copyrights and any related technology utilised under or in connection with Our services, including but not limited to all intellectual property rights associated therewith.",
        "No title to or ownership of any of the foregoing is granted or otherwise transferred to You or any other entity or person under these Terms and Conditions. You or anyone acting in your capacity shall not reverse engineer, disassemble, decompile or otherwise attempt to discover the source code or trade secrets for any of Our services or related technology. "]
}, {
    title: "18. Limitations",
    content: [
        "Under no circumstances will We or any of Our parents, affiliates or vendors (or any officers, directors, employees or agents of the parties, or its parents, affiliates or vendors) be liable for any indirect, incidental, consequential, special or exemplary damage or loss suffered or incurred by You, regardless of the form of action, or any loss of revenue, profits or business, anticipated savings, loss of goodwill or reputation, costs of delay, lost or damaged data, or the incurring of liability for loss or damage of any nature whatsoever suffered by third parties, all whether in contract, strict liability or tort (including negligence), regardless of whether it has been advised of the possibility of such damages.",
        "Our services are provided on an “as is,” “as available” basis without any representations or warranties. We do not represent or warrant that Our services will be uninterrupted, timely, secure, accurate, complete, or entirely error-free. We may suspend or withdraw or restrict the availability of all or any part of Our Services for business and operational reasons. We will try to give you reasonable notice of any suspension or withdrawal.",
        "You may not rely upon any representation or warranty regarding Our Services by any third party in contravention of the foregoing statements.",
        "You understand and agree that We shall bear no risk with respect to Your sale of products or services."
    ]
}, {
    title: "19. Force Majeure",
    content: [ "We will not be liable for any losses arising out of the delay or interruption in the provision of Our Services or the performance of Our obligations under these Terms and Conditions due to any acts of God, acts of civil or military authorities, civil disturbances, wars, strikes or other labour disputes, lockdowns, pandemics, epidemics, fires, transportation contingencies, interruptions in telecommunications, utility, internet services or network provider services, acts or omissions of a third party, infiltration or disruption of Our Services by a third party by any means, including without limitation, Distributed Denial of Services Attack (DDoS), software viruses, Trojan horses, worms, time bombs or any other software program or technology designed to disrupt or delay Our Services and the use of Our Unified Payment Platform, or other catastrophes or any other occurrences which are beyond Our reasonable control (each a “Force Majeure Event”), provided that We will use commercially reasonable efforts to minimise any delays or interruptions resulting from the Force Majeure Event." ]
}, {
    title: "20. Waiver",
    content: ["Our failure to insist on or enforce strict performance of any provision of these Terms and Conditions or to exercise any right or remedy under these Terms and Conditions or applicable law will not be construed as a waiver or relinquishment to any extent of the right to assert or rely upon any such provision, right or remedy in that or any other instance; rather, the same will be and remain in full force and effect. "]
}, {
    title: "21. Amendments",
    content: ["We reserve the right to amend these Terms and Conditions at any time and do a publication and/or a written or electronic notice to Customer not less than seven (7) days prior to the effective date of such amendment. Where any Customer does not agree to such amendments, such Customer’s sole remedy is to immediately terminate its enrolment to Our Services or platform upon written notice to its bank or third party who enrolled it or signed it up for Our Services. "]
}, {
    title: "22. Compliance",
    content: [
        "In connection with the exercise of Your rights and obligations under these Terms and Conditions (including, without limitation, any related to individual privacy), You shall comply, at Your own expense, with all laws, policies, guidelines, regulations, ordinances, rules applicable to Customer, these Terms and Conditions, end user data or Transactions conducted on Our platform or services and/or orders of any governmental authority or regulatory body having jurisdiction over the subject matter hereof.",
        " You shall not use Our Unified Payment Platform, services or platform in any manner, or in furtherance of any activity that may cause Us to become subject to investigation, prosecution, or legal action. "]
}, {
    title: "23. Governing Law and Dispute Resolution",
    content: [
        "These Terms and Conditions shall be governed and construed in accordance with the laws of the Federal Republic of Nigeria.",
        "We shall do all within Our power to settle amicably any difference of disputes concerning the interpretation or performance of these Terms and Conditions in accordance with Our operational guidelines, failing such amicable settlement; same shall be settled by arbitration in accordance with the Arbitration and Conciliation Act, Cap A 19, LFN 2004.",
        "The sole Arbitrator for any such arbitration will be appointed by the President Chartered Institute of Arbitrators, UK (Nigeria branch) to preside over the dispute.",
        "The Arbitrator shall have experience in the area of dispute.",
        "The place of Arbitration shall be Lagos. The arbitration shall be conducted in the English language. Relevant documents in other languages shall be translated into English if the arbitrators so direct.",
        "Any such arbitration shall once commenced, be completed within 3 (three) Calendar months or any additional time frame to be determined by the Parties and the Arbitrator at the expiration of the first 3 (three) months. Such extension shall not however exceed 1 (one) Calendar month.",
        "A Party shall not be entitled to suspend the performance of its obligations under these Terms and Conditions because an arbitral proceeding has been initiated. Without prejudice to the right to seek interim preservative orders from a court of competent jurisdiction, the decision of the arbitrators shall be final and binding on the Parties.",
        "You agree that if either You or Us commence arbitration or litigation, the prevailing party shall be entitled to recover reasonable attorney’s fees and any other costs incurred which the prevailing party may be entitled.",
        "The process by which any proceedings are begun in Nigeria or elsewhere may be served on You by being delivered to the address You supplied to Your bank or third party who enrolled or signed You up for the use of Our Unified Payment Platform, services or platform."
    ]
}, {
    title: "24. General", isOpened: true,
    content: [
        "We will not be responsible to You for any indirect consequential or special damages arising from any act or omission by You or any third party for whom You are responsible for and whether arising in contract, statute or tort.",
        "You are responsible for your connection to the internet and all costs associated with that same connection.",
        "These Terms and Conditions shall be in addition to and not in derogation from the regulations, circulars, orders, notifications, instructions issued by Central Bank of Nigeria (CBN) from time to time, including any Mobile Banking Regulations (hereinafter collectively referred to as the “Regulations”).",
        "We may retain the details of Transactions or payments You make via Our platform or services after the Transaction is completed. This information will only be made available to third parties if required by law.",
        "You agree that your Unique Information, including your personal information, and your Transactions on Our platform or services may be recorded and stored for record keeping purposes for up to seven (7) years from date of closure of account; or any other length of time as required by law.",
        "Words and phrases with initial letters capitalized and not otherwise defined herein shall have the meaning set forth below."
    ]
}];
