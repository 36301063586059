import React, { useState } from "react";
import ReactSelect from "react-select";

const Step1 = ({ updateFormData, formData, channelOptions }) => {
    const [phoneNumber, setPhoneNumber] = useState(formData.phoneNumber || '');

    const CustomStyle = {
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected ? "#F0F7FF" : "transparent",
            color: state.isSelected ? "#062558" : "#A3AEC0",
            fontWeight: 400,
            fontSize: "13px",
            lineHeight: "15px",
        })
    };

    const isValid = (number) => {
        return number && number.trim().length >= 7;
    };

    const onChange = (event) => {
        if(formData.phoneNumber) return;
        setPhoneNumber(event.target.value);
    };

    return <div>
        <div style={{ marginTop: "40px", textAlign: "center" }}>
            <span className="container-title">Payment Request of</span>
            <div>
                <span className="currency-amount">{formData.amount}</span>
                <span className="muted">{formData.currency.toUpperCase()}</span>
            </div>
        </div>

        <div>
            <div>
                <input
                    id="phoneNumber"
                    type="tel" maxLength={13}
                    value={phoneNumber || ''}
                    className="phone"
                    onChange={ onChange }
                    readOnly={formData.phoneNumber}
                />
            </div>
            <div style={{ marginTop: "3rem" }}>
                <p style={{ textAlign: "center", color: "#062558", fontWeight: 500, fontSize: "0.875rem", lineHeight: "1.25rem" }} > Select one payment method to proceed </p>
                <ReactSelect
                    isSearchable={false}
                    styles={CustomStyle}
                    options={channelOptions}
                    value={formData.channel}
                    onChange={sel => updateFormData({ channel: sel })}
                    formatOptionLabel={opt => (
                        <div style={{ textAlign: 'center' }}>
                            <img
                                alt={opt.label}
                                src={opt.img}
                                style={{ display: 'inline-block', width: '21px', height: '21px', marginRight: '1.5rem', verticalAlign: "middle" }}
                            />
                            <span style={{ display: 'inline-block', width: '4rem', textAlign: 'left' }} >{opt.label}</span>
                        </div>
                    )}
                />
            </div>
            <button className={formData.disableButton || formData.isLoading || !isValid(phoneNumber) ? "btn-disabled btn-submit" : "btn-default btn-submit"}
                style={{ marginBottom: "20px" }}
                disabled={formData.disableButton || formData.isLoading || !isValid(phoneNumber)}
                type="button"
                onClick={e => {
                    e.preventDefault();
                    updateFormData({ step: 4, phoneNumber });
                }}>
                {formData.isLoading ? <img className="spinner grayscale" src={"assets/images/spinner.svg"} style={{ margin: "-10px 0 -10px", "width": "28px" }} alt="Loading..." /> : "Proceed"}
            </button>
        </div>
    </div>;

};

export default Step1;
