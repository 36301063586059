import React, { useCallback, useEffect, useState } from "react";

import * as Util from "../common/Util";
import TransactionProgressBar from "../common/components/TransactionProgressBar";

const PaymentStatus = ({ props }) => {

	const [transaction, setTransaction] = useState({
		"amount": props.amount,
		"currency": props.currency,
		"failed": -1
	});

	const [status, updateStatus] = useState({
		"title": 'Request sent successfully',
		"amount": props.amount,
		"currentProgress": 0,
		"nextProgress": 0,
		"isLoading": true,
		"failed": false,
		"subtitle": ''
	});

	const refreshTransactionDetails = useCallback(() => {
		const env = props.isTest ? (props.useSandbox ? '.sandbox' : '.staging') : '';
		const url = `https://api${env}.vendy.money/public/transactions/get-transaction?id=${props.ref}`;
		const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.key };
		fetch(url, { headers }).then(response => response.json()).then(data => {
			if (!(data.status && data.code === 200 && data.data)) return;

			let transaction = data.data;
			setTransaction(transaction);
			let amount = parseFloat(transaction.amount.toString());
			amount = amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
			let isLoading = transaction['failed'] !== 0 && transaction['failed'] !== 1;
			let failed = !isLoading && (transaction['flag'] === 1 || transaction['reversed'] === 1 || transaction['failed'] === 1);

			let title = isLoading ? "Request sent successfully" : "Transaction " + (failed ? "failed" : "successful");
			let subtitle = "We have just sent a payment request of "+transaction.currency?.toUpperCase()+amount;
			if (transaction.msisdn) subtitle += " to +"+transaction.msisdn+".";

			let suffix;
			let nextProgress = 0;
			if (transaction['failed'] === 1 || transaction['failed'] === 0) {
				nextProgress = 3;
				suffix = "";
			} else if (transaction['debited'] === 1) {
				suffix = "Processing payment...";
				nextProgress = 2;
			} else if (transaction['delivered'] === 1) {
				nextProgress = 1;
				suffix = null;
			}

			if (isLoading) subtitle += suffix ?? " Waiting for the customer's authorization...";
			if (!isLoading) {
				subtitle = "Payment request of "+transaction.currency?.toUpperCase()+transaction.amount+" to +"+transaction.msisdn+" "+(failed ? "failed" : "is successful");
				if (failed) {
					if ((transaction.reason || '').trim().length > 0) subtitle += " due to " + transaction.reason;
					subtitle += ".";
				}
			}

			if (status.nextProgress !== nextProgress) updateStatus(prevState => { return { ...prevState, "currentProgress": status.nextProgress, nextProgress }; });
			setTimeout(_ => {
				updateStatus(prevState => {
					return { ...prevState, amount, isLoading, failed, title, subtitle };
				});
			}, status.nextProgress !== nextProgress ? 650 : 10);
			if (!transaction || (transaction['failed'] == null || transaction['failed'] === -1)) {
				setTimeout(_ => refreshTransactionDetails(), 2000);
			}
		}).catch((err) => {
			console.error(err);
			setTimeout(_ => refreshTransactionDetails(), 2000);
		});
	}, [props.isTest, props.useSandbox, props.key, props.ref, status.nextProgress]);

	useEffect(() => {
		let subtitle = "We have just sent a payment request of "+transaction.currency?.toUpperCase()+props.amount;
		if (transaction.phoneNumber) subtitle += " to +" + transaction.phoneNumber;
		subtitle += ". Waiting for the customer's authorization...";
		updateStatus(prevState => { return { ...prevState, subtitle }; });
		setTimeout(_ => refreshTransactionDetails(), 3000);
	}, [props.amount, refreshTransactionDetails, transaction.currency, transaction.phoneNumber]);

	return <div style={{ padding: "40px 30px", textAlign: "center" }}>
		{
			status.isLoading ? <div style={{ padding: "20px 0", textAlign: "center" }}>
				<img className="spinner" src="assets/images/spinner.svg" style={{}} alt="Loading..." />
			</div> : status.failed ? <img className="failed-status" src="assets/images/failed.png" alt="failed" /> :
				<img className="success-status" src="assets/images/success.png" alt="successful" />
		}
		<h2 className="container-title">{status.title}</h2>
		<h4 className="container-title" style={{ fontSize: "12px", marginTop: "8px" }}>{status.subtitle}</h4>
		{status.isLoading ? <div style={{ marginTop: "30px" }}>
			<TransactionProgressBar currentValue={status.currentProgress} newValue={status.nextProgress} />
		</div> : <div />}
		{!status.isLoading ? <button className={status.failed ? "btn-default-alt" : "btn-default"} type="reset" onClick={e => { e.preventDefault(); Util.closeFrame(transaction, props.domain); }}>Close</button> : ''}
	</div>;
};

export default PaymentStatus;
