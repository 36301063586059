import React, { useState } from "react";

const Accordion = ({ props }) => {
    const [opened, setOpened] = useState(props.isOpened || false);
    const domain = window.location.protocol+"//"+window.location.host;

    return <div className="accordion">
        <div className="accordion-title" onClick={() => setOpened(!opened)}>
            <span>{ props.title }</span>
            <img className="arrow" src={domain+(opened ? "/assets/images/chevron-up.svg" : "/assets/images/chevron-down.svg")} alt={opened ? "up" : "down"} />
        </div>
        <div className="accordion-content" style={{ display: opened ? "block" : "none" }}>{ props.content.map((content, i)=> <ul key={i} style={{ listStyleType:'none', padding:'0'}}><li >{content}</li></ul>) }</div>
    </div>;
}

export default Accordion;
